<template>
    <v-container v-if="dialog">
        <v-dialog v-model="dialog" scrollable max-width="90%">
        <v-card>
            <v-form ref="rateForm" id="rateForm" v-model="valid" lazy-validation>
            <v-card-title>Holidays Rate {{selectedActivity}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 550px;">
                <v-row>
                <v-col cols="10">
                    <v-card outlined class="ma-3 mt-5" width="">
                    <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Description
                                </th>
                                <th class="text-left">
                                    Date From
                                </th>
                                <th class="text-left">
                                    Date To
                                </th>
                                <th class="text-left">
                                    Mark-up (%)
                                </th>
                                <th class="text-left">asd</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in rates" :key="idx">
                                <td>
                                    <v-text-field v-model="item.description" outlined dense hide-details="auto" :rules="reqRule" class="compact-form"></v-text-field>
                                </td>
                                <td>
                                    <v-menu v-model="item.dateFromMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="item.date_from" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined :rules="reqRule" class="compact-form"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.date_from"></v-date-picker>
                                    </v-menu>
                                </td>
                                <td>
                                    <v-menu v-model="item.dateToMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="item.date_to" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined :rules="reqRule" class="compact-form"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.date_to" :min="item.date_from"></v-date-picker>
                                    </v-menu>
                                </td>
                                <td>
                                    <v-text-field v-model="item.markup_perc" type="number" outlined dense hide-details="auto" :rules="reqRule"  @wheel="$event.target.blur()" class="compact-form" suffix="%"></v-text-field>
                                </td>
                                <td>
                                    <v-icon color="red" @click="removeRate(idx)">mdi-minus-circle-outline</v-icon>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    </v-card>
                </v-col>
                <v-col align-self="end" class="mb-6">
                    <v-btn small color="green" class="white--text" @click="addRate">
                        <v-icon small>mdi-plus</v-icon> Add
                    </v-btn>
                </v-col>

                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <v-btn @click="dialog=false">Cancel</v-btn>
            <v-btn color="primary" @click="save()">Save</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
        </v-dialog>
        <v-overlay :value="loading" style="z-index:999999">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>            
    </v-container>    
</template>

<script>

export default {
    components: {
    },  
    data: () => ({
        valid: true,
        dialog: false,
        loading: false,
        selectedActivity: '',
        rates: [],
        reqRule: [v => !!v || 'Harus diisi!'],
    }),
    methods: {
        openDialog(_id) {
            this.rates = []
            this.dialog = true
            this.selectedActivity = _id
            this.loading = true
            this.$store.dispatch('holiday/getObj', this.selectedActivity).then((response) => {
                if (response) {
                    this.rates = response.rates
                }
                this.loading = false
            })
        },

        async save(){
            await this.$refs.rateForm.validate();
            if (!this.valid) return;

            var obj = {}
            obj._id = this.selectedActivity
            obj.rates = this.rates
            this.loading = true
            this.$store.dispatch('holiday/postObj', obj).then(response => {
                this.loading = false
                if (response.result.ok) {
                    this.dialog = false
                }
            })
            
        },
        addRate() {
            this.rates.push({
                add: true,
                description: '',
                date_from: '',
                date_to: '',
                markup_perc: ''
            })
        },
        removeRate(idx) {
            this.rates.splice(idx, 1)
        },
 
    }
}
</script>