<template>
  <v-container>
    <v-toolbar flat style="background-color:#EBEBEB">
        <v-btn color="pink" dark top right @click="newActivity()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="keyword" placeholder="Cari..." hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="refreshList">Refresh</v-btn>
    </v-toolbar>

    <!-- <div class="pa-2">
      <v-btn color="pink" dark top right @click="newActivity()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

    </div> -->
    <v-flex>
      <v-layout wrap>
        <v-card class="ma-2 pa-0" style="width:250px; height:230px" v-for="(obj, i) in activities" :key="i">
          <v-card-text style="height:180px">
            <v-img max-width="100%" height="130px" gradient 
            :src="$store.state.config.mediaHost + obj._id + '/thumbnail/' + obj.thumbnail.name" class="rounded"></v-img>
            {{obj.name}}
            <v-row style="position:absolute; bottom:12px; right: 50px">
              <!-- <v-col>
                <span class="caption">{{obj.location.code}}</span>
              </v-col> -->
              <!-- <v-spacer></v-spacer> -->
              <v-col>
                <span class="caption text-right" :class="(obj.highlight)?'highlight':''">{{(obj.highlight)?'Highlight':''}}</span>
              </v-col>
              <v-col>
                <span class="caption text-right" :class="(obj.is_active)?'aktif':'nonaktif'">{{(obj.is_active)?'Aktif':'Nonaktif'}}</span>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-menu top left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editActivity(obj._id)">
                  <v-list-item-title>Edit Activity</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openGalleriesDlg(obj._id)">
                  <v-list-item-title>Add Galleries</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openHollidaysRate(obj._id)">
                  <v-list-item-title>Holidays Rate</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item>
                  <v-list-item-title>Add Options</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>

      </v-layout>
    </v-flex>
    <v-dialog v-model="dialog" v-if="dialog" scrollable width="80%" persistent>
      <v-card>
        <!-- <v-card-title>New activity</v-card-title> -->
        <v-toolbar flat>
            <v-toolbar-title>New Activity</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-switch class="pr-4" v-model="activity.is_active" dense hide-details="auto" :label="(activity.is_active)?'Aktif':'Tidak Aktif'"></v-switch>
        </v-toolbar>

        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-flex py-2>
              <v-layout wrap>
                <v-flex xs12 md6 px-2>
                  <span class="">Nama Kegiatan</span>
                  <v-text-field filled rounded dense v-model="activity.name" :rules="reqRule" required hide-details="auto" @blur="createSlug()"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Slug (SEO Purpose URL Name)</span>
                  <v-text-field filled rounded dense v-model="activity.slug" :rules="reqRule" required hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Kategori Kegiatan</span>
                  <v-select filled rounded dense :items="categories" item-text="name" item-value="code" v-model="activity.category" return-object :rules="reqRule" required hide-details="auto"></v-select>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Propinsi</span>
                  <v-select filled rounded dense :items="locations" item-text="name" item-value="code" v-model="activity.location" return-object :rules="reqRule" required hide-details="auto"></v-select>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Kab/Kota</span>
                  <v-text-field filled rounded dense v-model="activity.loc_details" hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Area/Kecamatan</span>
                  <v-text-field filled rounded dense v-model="activity.district_area" hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Deskripsi Kegiatan</span>
                  <v-textarea filled rounded dense v-model="activity.description" rows="3" hide-details="auto"></v-textarea>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Kordinat Lokasi</span>
                  <v-textarea filled rounded dense v-model="activity.map" rows="3" hide-details="auto"></v-textarea>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Titik Kumpul</span>
                  <v-text-field filled rounded dense v-model="activity.meeting_point" hide-details="auto"></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 md6 px-2>
                  <span class="">Rincian Kegiatan</span>
                  <v-textarea filled rounded dense v-model="activity.itinerary" rows="3"></v-textarea>
                </v-flex> -->
                <!-- <v-flex xs12 md6 px-2>
                  <span class="">Pricing Base</span>
                  <v-select filled rounded dense :items="pricing_bases" v-model="activity.pricing_base" return-object></v-select>
                </v-flex> -->
                <v-flex xs12 md6 px-2>
                  <span class="">Harga Terendah</span>
                  <v-text-field filled rounded dense v-model.number="activity.price" type="number" :rules="reqRule" prefix="Rp" @wheel="$event.target.blur()" hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Adventure Level</span>
                  <v-select filled rounded dense :items="adventure_level" v-model="activity.adventure_level" hide-details="auto"></v-select>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Usia Minimum</span>
                  <v-text-field filled rounded dense v-model.number="activity.minimum_age" type="number" suffix="Tahun" hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Jumlah Hari</span>
                  <v-text-field filled rounded dense v-model.number="activity.days" type="number" suffix="Hari" @wheel="$event.target.blur()" hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Durasi Kegiatan</span>
                  <v-text-field filled rounded dense v-model.number="activity.duration" type="number" suffix="Menit" @wheel="$event.target.blur()" hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Batas Pemesanan (H-)</span>
                  <v-text-field filled rounded dense v-model.number="activity.minimum_date" type="number" suffix="Hari" @wheel="$event.target.blur()" hide-details="auto" :rules="reqNumRule" required></v-text-field>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Thumbnail Kegiatan</span>
                  <v-file-input filled rounded dense 
                  @change="onFileChanged" v-model="vThumbnail"
                  :rules="fileRules" accept="image/*" show-size counter></v-file-input>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Syarat & Ketentuan</span>
                  <tiptap-vuetify class="pb-2" v-model="activity.tnc" :extensions="extensions"/>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Kebijakan Pembatalan</span>
                  <tiptap-vuetify class="pb-2" v-model="activity.cancelation_policy" :extensions="extensions"/>
                </v-flex>
                <v-flex xs6 md3 px-2 pt-6>
                  <v-checkbox v-model="activity.display_adventurecamp" label="Tampilkan di Adventure Camp" hide-details class="mt-0 mb-2"></v-checkbox>
                </v-flex>
                <v-flex xs6 md3 px-2 pt-6>
                  <v-checkbox v-model="activity.display_adventuretrip" label="Tampilkan di Adventure Trip" hide-details class="mt-0 mb-2"></v-checkbox>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <span class="">Spot Admin</span>
                  <v-select filled rounded dense :items="admins" item-text="name" item-value="email" v-model="activity.spot_admin" hide-details="auto"></v-select>
                </v-flex>
                <v-flex xs6 md3 px-2 pt-6>
                  <v-checkbox v-model="activity.highlight" label="Highlight" hide-details class="mt-0 mb-2"></v-checkbox>
                </v-flex>
                <v-flex xs12 md12 py-3>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 md6>
                  <span class="text-h5 font-weight-medium">Vendor</span>
                </v-flex>
                <v-flex xs12 md6>
                  <span class="">Harga Vendor</span>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <v-autocomplete filled rounded dense :items="vendors" item-text="name" item-value="code" v-model="activity.vendor"  :rules="reqRule" required hide-details="auto"></v-autocomplete>
                </v-flex>
                <v-flex xs12 md6 px-2>
                  <v-select filled rounded dense :items="vendorPricing"  hide-details="auto"></v-select>
                </v-flex>
                <v-flex>
                  <v-row class="py-4">
                    <v-col cols="2" my-2>
                      <span class="font-weight-bold">Pembagian Cost</span>
                    </v-col>
                    <v-col cols="2" my-2>
                      <v-text-field v-model="activity.cost_code_1" outlined dense hide-details="auto" maxlength="5"></v-text-field>
                    </v-col>
                    <v-col cols="2" my-2>
                      <v-text-field v-model="activity.cost_code_2" outlined dense hide-details="auto" maxlength="5"></v-text-field>
                    </v-col>
                    <v-col cols="2" my-2>
                      <v-text-field v-model="activity.cost_code_3" outlined dense hide-details="auto" maxlength="5"></v-text-field>
                    </v-col>
                  </v-row>
                </v-flex>
                <v-flex xs12 md12 py-3>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 md12>
                  <span class="text-h5 font-weight-medium">Selections</span>
                </v-flex>
                <div style="width:100%">
                  <v-card v-for="(sel, idx) in activity.selections" :key="idx">
                    <v-layout  wrap style="" class="rounded-lg my-3 elevation-1 pa-3">
                      <v-flex xs12 md6 px-2>
                            <v-row class="py-0">
                              <v-col cols="6">
                                <v-checkbox v-model="sel.default" label="Default" hide-details class="mt-0 mb-2"></v-checkbox>
                              </v-col>
                              <v-col cols="6">
                                <v-checkbox v-model="sel.disabled" label="Disable" hide-details class="mt-0 mb-2"></v-checkbox>
                              </v-col>
                            </v-row>
                            <span class="">Nama Paket</span>
                            <v-text-field filled rounded dense v-model="sel.name" :rules="reqRule" required hide-details="auto"></v-text-field>
                            <span class="">Deskripsi</span>
                            <v-textarea filled rounded dense v-model="sel.desc" rows="3" hide-details></v-textarea>
                            <!-- <span class="">Itinerary <em class="red--text">(Will be removed on next release v1.1.5)</em></span>
                            <v-textarea filled rounded dense v-model="sel.itinerary" rows="5" hide-details="auto" class="pb-1" readonly></v-textarea> -->
                            <span class="">New Itinerary</span>
                            <tiptap-vuetify class="pb-2"
                              v-model="sel.itinerary_new"
                              :extensions="extensions"
                            />
                            <v-row class="py-0">
                              <v-col cols="6" class="">
                                <span class="">Minimum Order</span>
                                <v-text-field filled rounded dense v-model.number="sel.min_order" type="number" @wheel="$event.target.blur()"  :rules="reqRule" required hide-details="auto"></v-text-field>
                              </v-col>
                              <v-col cols="6" class="">
                                <span class="">Maksimum Order</span>
                                <v-text-field filled rounded dense v-model.number="sel.max_order" type="number" @wheel="$event.target.blur()" hide-details="auto" placeholder="Kosongkan Jika Unlimited"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row class="py-0">
                              <v-col cols="6" class="pt-0">
                                <span class="">Pricing Base</span>
                                <v-select filled rounded dense :items="pricing_bases" v-model="sel.pricing_base" :rules="reqRule" required hide-details="auto"></v-select>
                              </v-col>
                              <v-col cols="6" class="pt-0">
                                <span class="">Diskon Weekday</span>
                                <v-text-field filled rounded dense v-model="sel.weekday_discount" type="number" @wheel="$event.target.blur()" hide-details="auto" suffix="%" :rules="reqRule" required></v-text-field>
                              </v-col>
                            </v-row>
                            <span class="">Diskon Umum</span>
                            <v-row class="py-0">
                              <v-col cols="7" class="py-0">
                                <v-radio-group v-model="sel.disc_type" row dense>
                                  <v-radio label="Persentase (%)" value="perc"></v-radio>
                                  <v-radio label="Amount (Rp)" value="amount"></v-radio>
                                </v-radio-group>                                
                              </v-col>
                              <v-col cols="5" class="py-3">
                                <v-text-field filled rounded dense v-model="sel.global_discount" 
                                  :suffix="(sel.disc_type=='perc')?'%':''" :prefix="(sel.disc_type=='amount')?'Rp':''"></v-text-field>
                              </v-col>
                            </v-row>
                          <!-- </v-flex>
                          <v-flex xs12 px-1> -->
                            <div class="font-weight-medium body-1 py-2">
                              Options  <v-btn text small color="primary" @click="addOption(sel.options)">Tambah</v-btn>
                            </div>
                            <v-card v-for="(opt, idx3) in sel.options" :key="idx3" style="border: 1px solid #C8C8C8;" class="rounded-lg mt-0 mb-2 pa-2" elevation="0">
                              <v-btn @click="removePrice(sel.options, idx3)" color="secondary" absolute fab dark x-small top right style="top:5px!important;right:5px!important">
                                  <v-icon>mdi-close</v-icon>
                              </v-btn>
                              <span class="">Nama Option</span>
                              <v-text-field filled rounded dense v-model="opt.name" hide-details></v-text-field>
                              <span class="">Deskripsi</span>
                              <v-text-field filled rounded dense v-model="opt.desc" hide-details></v-text-field>
                              <v-row>
                                <v-col cols="4">
                                  <span class="">Unit Price</span>
                                  <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.price" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <span class="">Published Rate</span>
                                  <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.published_rate" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  <span class="">Unit Cost ({{activity.cost_code_1}})</span>
                                  <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.cost" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                                </v-col>
                                <v-col cols="4" v-if="activity.cost_code_2">
                                  <span class="">Unit Cost ({{activity.cost_code_2}})</span>
                                  <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.cost_2" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                                </v-col>
                                <v-col cols="4" v-if="activity.cost_code_3">
                                  <span class="">Unit Cost ({{activity.cost_code_3}})</span>
                                  <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.cost_3" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="3" class="pt-4">
                                  <span class="">Default Qty</span>
                                </v-col>
                                <v-col cols="9">
                                  <v-radio-group dense v-model="opt.defaultQty" row style="margin-top:0px">
                                    <v-radio label="1" value="1"></v-radio>
                                    <v-radio label="Mengikuti Qty Selection" value="2"></v-radio>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="3" class="pt-4">
                                  <span class="">Maximum Qty</span>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field filled rounded dense v-model.number="opt.max_qty" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card>
                          <!-- </v-flex>
                        </v-layout> -->
                      </v-flex>
                      <v-flex xs12 md6 px-2>
                        <div class="font-weight-medium body-1">Includes</div>
                        <v-card style="border: 1px solid #C8C8C8;" class="rounded-lg my-2 pa-2 pt-0" elevation="0">
                          <v-layout wrap class="">
                            {{ checkIncObj(sel) }}
                            <v-checkbox :label="inc.desc" v-model="sel.includes" :value="inc._id" class="pr-2" hide-details v-for="(inc,idx_inc) in includes" :key="idx_inc"></v-checkbox>
                          </v-layout>
                        </v-card>
                        <div class="font-weight-medium body-1">Pricing</div>
                        <v-card v-for="(price, idx2) in sel.price" :key="idx2" style="border: 1px solid #C8C8C8;" class="rounded-lg my-2 pa-2" elevation="0">
                          <v-btn v-if="idx2>0" @click="removePrice(sel.price, idx2)" color="secondary" absolute fab dark x-small top right style="top:5px!important;right:5px!important">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <span class="">Minimum Qty</span>
                          <v-text-field filled rounded dense v-model.number="price.min_qty" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                          <v-row>
                            <v-col cols="4">
                              <span class="">Unit Price</span>
                              <v-text-field prefix="Rp" filled rounded dense v-model.number="price.unit_price" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <span class="">Published Rate (Vendor)</span>
                              <v-text-field prefix="Rp" filled rounded dense v-model.number="price.published_rate" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="4">
                              <span class="">Unit Cost ({{activity.cost_code_1}})</span>
                              <v-text-field prefix="Rp" filled rounded dense v-model.number="price.unit_cost" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                              <v-checkbox v-model="price.unit_cost_fixed" dense label="Fixed"></v-checkbox>
                            </v-col>
                            <v-col cols="4" v-if="activity.cost_code_2">
                              <span class="">Unit Cost ({{activity.cost_code_2}})</span>
                              <v-text-field prefix="Rp" filled rounded dense v-model.number="price.unit_cost_2" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                              <v-checkbox v-model="price.unit_cost_2_fixed" dense label="Fixed"></v-checkbox>
                            </v-col>
                            <v-col cols="4" v-if="activity.cost_code_3">
                              <span class="">Unit Cost ({{activity.cost_code_3}})</span>
                              <v-text-field prefix="Rp" filled rounded dense v-model.number="price.unit_cost_3" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                              <v-checkbox v-model="price.unit_cost_3_fixed" dense label="Fixed"></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-card>
                        <div class="text-right"><v-btn small text color="primary" @click="addPrice(sel.price)">Add Price</v-btn></div>
                        <v-divider></v-divider>
                        <div class="font-weight-medium body-1 py-2">
                          Variants  <v-btn text small color="primary" @click="addVariant(sel.variants)">Tambah</v-btn>
                        </div>
                        <v-card v-for="(opt, idx4) in sel.variants" :key="idx4" style="border: 1px solid #C8C8C8;" class="rounded-lg mt-0 mb-2 pa-2" elevation="0">
                          <v-btn @click="removePrice(sel.variants, idx4)" color="secondary" absolute fab dark x-small top right style="top:5px!important;right:5px!important">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <span class="">Nama Varian</span>
                          <v-text-field filled rounded dense v-model="opt.name" hide-details></v-text-field>
                          <span class="">Tambahan Harga</span>
                          <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.add_price" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                        </v-card>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="5">
                            <v-checkbox v-model="sel.avail_check" label="Availability Check" hide-details="auto"></v-checkbox>
                          </v-col>
                          <v-col cols="7" v-if="sel.avail_check">
                            <span class="">Jumlah Ketersediaan</span>
                            <v-text-field filled rounded dense v-model.number="sel.avail_qty" type="number" @wheel="$event.target.blur()"  :rules="(sel.avail_check)?reqRule:''" required hide-details="auto" :suffix="sel.pricing_base"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            Jika fitur Availability Check diaktifkan, maka:<br>
                            1. System akan melakukan pengecekan ketersediaan saat customer booking<br>
                            2. Saat customer booking, status order otomatis terkonfirmasi dan customer bisa langsung bayar<br>
                            3. Batas waktu pembayaran 30 menit dari waktu booking
                          </v-col>
                        </v-row>

                      </v-flex>
                      <v-btn v-if="idx>0" @click="removeSelection(activity.selections, idx)" color="secondary" absolute fab dark x-small top right style="top:5px!important;right:5px!important">
                          <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-card>
                  <div class="text-right"><v-btn small text color="primary" @click="addSelection(activity.selections)">Add Selection</v-btn></div>

                </div>
                <v-flex xs12 md12>
                  <span class="text-h5 font-weight-medium">General Options</span>
                </v-flex>
                <v-card v-for="(opt, idx4) in activity.general_options" :key="idx4" style="border: 1px solid #C8C8C8;" class="rounded-lg mt-0 mb-2 mr-2 pa-2" elevation="0">
                  <v-btn @click="removePrice(activity.general_options, idx4)" color="secondary" absolute fab dark x-small top right style="top:5px!important;right:5px!important">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <span class="">Nama Option</span>
                  <v-text-field filled rounded dense v-model="opt.name" hide-details></v-text-field>
                  <span class="">Deskripsi</span>
                  <v-text-field filled rounded dense v-model="opt.desc" hide-details></v-text-field>
                  <v-row>
                    <v-col cols="4">
                      <span class="">Unit Price</span>
                      <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.price" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <span class="">Published Rate</span>
                      <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.published_rate" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <span class="">Unit Cost ({{activity.cost_code_1}})</span>
                      <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.cost" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="activity.cost_code_2">
                      <span class="">Unit Cost ({{activity.cost_code_2}})</span>
                      <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.cost_2" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="activity.cost_code_3">
                      <span class="">Unit Cost ({{activity.cost_code_3}})</span>
                      <v-text-field prefix="Rp" filled rounded dense v-model.number="opt.cost_3" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="pt-4">
                      <span class="">Default Qty</span>
                    </v-col>
                    <v-col cols="9">
                      <v-radio-group dense v-model="opt.defaultQty" row style="margin-top:0px">
                        <v-radio label="1" value="1"></v-radio>
                        <v-radio label="Mengikuti Qty Selection" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="pt-4">
                      <span class="">Maximum Qty</span>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field filled rounded dense v-model.number="opt.max_qty" type="number" hide-details @wheel="$event.target.blur()"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="font-weight-medium body-1 py-2">
                  <v-btn text small color="primary" @click="addOption(activity.general_options)">Tambah</v-btn>
                </div>
              </v-layout>
            </v-flex>
          </v-form>          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveActivity()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gallery-update ref="gallery_update" />
    <holidays-rate ref="holidays_rate" />
    <v-overlay :value="onProgress" style="z-index:999">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
    </v-overlay>  

  </v-container>
</template>
<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import GalleryUpdate from '@/components/GalleryUpdate'
import HolidaysRate from '@/components/HolidaysRate'
export default {
  components: {
    GalleryUpdate,
    TiptapVuetify,
    HolidaysRate
  },  

  data: () => ({
    drawer: true,
    dialog: false,
    valid: true,
    activity: {
        // thumbnail:{},
        // minimum_date: 2,
        // is_active: 1
    },
    thumbnail: '',
    pricing_bases: ['pax','mobil','motor','vehicle','group', 'boat','tenda','unit','kavling'],
    adventure_level: ['Beginner','Medium','Hard'],
    fileRules: [
      v => !v || v.size < 102400 || 'Tidak boleh lebih dari 100KB!'
    ],
    onProgress: false,
    vThumbnail: null,
    reqRule: [v => !!v || 'This field is required'],
    reqNumRule: [v => v !== undefined && v !== null && v !== '' || v === 0 || 'This field is required'],
    spotAdminEditable: true,
    vendorPricing: ['Mark-up','Diskon'],
    keyword: '',
    activities: [],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    // starting editor's content
    content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `    
  }),
  methods: {
    addCostCode() {
      if (!this.activity.cost_codes) this.$set(this.activity, 'cost_codes', [])
      this.activity.cost_codes.push({
        code: '',
        name: ''
      })
    },
    async refreshList() {
      this.onProgress = true
      console.log('cat ', this.$route.params.category)
      if (this.$route.params.category=='vendor'){
        this.activities = await this.$store.dispatch('activity/getVendorActivities', encodeURI(this.profile.vendor_code))
      }
      else {
        this.activities = await this.$store.dispatch('activity/getObjsByCategory', this.$route.params.category)
      }
      this.onProgress = false
    },
    checkIncObj(sel) {
      if (!sel.includes) 
        this.$set(sel, 'includes', [])
      if (!sel.variants) 
        this.$set(sel, 'variants', [])
      if (!sel.options) 
        this.$set(sel, 'options', [])
    },
    addPrice(obj) {
      let last = {}
      if (obj.length>0) last = obj[obj.length-1]
      else last = {min_qty:0, unit_price:1}
      obj.push({min_qty:Number(last.min_qty)+1, unit_price:Number(last.unit_price)-1})
    },
    removePrice(obj, idx) {
      obj.splice(idx, 1)
    },
    removeSelection(obj, idx) {
      obj.splice(idx, 1)
    },
    addSelection(obj) {
      const short = require('short-uuid');
      let newObj = {
        _id: short.generate(),
        default: (obj.length)?false:true,
        name: '',
        desc: '',
        min_order: 1,
        weekday_discount: '',
        price: [],
        options: [],
        includes:[]
      }
      this.addPrice(newObj.price)
      console.log(newObj)
      obj.push(newObj)
    },
    addOption(obj) {
      let newObj = {
        name: '',
        desc: '',
        price: '',
        defaultQty: '1'
      }
      if (obj) obj.push(newObj)
      else {
        obj
      }
    },
    addVariant(obj) {
      let newObj = {
        name: '',
        add_price: 0
      }
      if (obj) obj.push(newObj)
      else {
        obj
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    newActivity() {
      this.dialog = true
      this.vThumbnail = null
      this.spotAdminEditable = true
      this.activity = {
        thumbnail:'', 
        slug: '',
        selections:[], 
        general_options:[],
        minimum_date:2, 
        is_active:true, 
        spot_admin:this.$store.state.profile.email
      }
      this.addSelection(this.activity.selections)
    },
    async saveActivity() {
      await this.$refs.form.validate();
      // console.log(this.valid)
      if (this.valid){
        this.onProgress = true
        
        this.$store.dispatch('activity/postObj', this.activity).then(response=> {
          if (response.result.ok) {
            setTimeout(() => {
              this.refreshList()
            }, 1000)
            this.activity = {thumbnail:''}
            this.onProgress = false
          }
        })
        this.dialog = false
      }
      else {
        alert('Form error! Periksa kolom yang perlu diisi!')
      }
    },
    editActivity(_id) {
      this.onProgress = true
      this.dialog = true
      this.vThumbnail = null
      this.$store.dispatch('activity/getObj', _id).then(response => {
        this.activity = response
        if (this.activity.thumbnail) {
          this.toDataURL(this.$store.state.config.mediaHost + this.activity._id + '/thumbnail/' + this.activity.thumbnail.name).then(response => {
            this.activity.thumbnail.base64 = response
            console.log(this.activity)
          })
        }
        if (this.activity.spot_admin) this.spotAdminEditable = false
        else this.spotAdminEditable = true
        if (this.activity.general_options == undefined) {
          this.$set(this.activity, 'general_options', [])
        }
        this.onProgress = false
      })
    },
    toDataURL(url) { 
        return new Promise((resolve) => {
            fetch(url).then(response => response.blob())
            .then(blob => {
                this.getBase64(blob).then((response)=>{
                  // var temp = {}
                  // temp.base64 = response
                  // this.activity.thumbnail = temp
                    // console.log(response)
                    resolve(response)
                })
            })
        })
    },
    getBase64(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject('Error: ', error);
            };
        })
    },
    onFileChanged(image) {
      if (!image) return
      this.getBase64(image).then((response)=>{
          var temp = {}
          temp.base64 = response
          temp.name = image.name
          temp.size = image.size
          temp.type = image.type
          temp.lastModified = image.lastModified
          this.activity.thumbnail = temp
          console.log(this.activity)
      })

    },
    openGalleriesDlg(_id){
      this.$refs.gallery_update.openDialog(_id)
    },
    openHollidaysRate(_id){
      this.$refs.holidays_rate.openDialog(_id)
    },
    createSlug() {
      if (this.activity.slug=='')
        this.activity.slug = string_to_slug(this.activity.name);
    }

  },
  watch: {
    keyword(val) {
      if (!val) val = ''
      this.activities = this.$store.state.activity.objs.filter((el) => {
        console.log(val)
        return el.name.toLowerCase().includes(val.toLowerCase()) ||
            el.description.toLowerCase().includes(val.toLowerCase()) ||
            el.location.name.toLowerCase().includes(val.toLowerCase()) ||
            el.loc_details.toLowerCase().includes(val.toLowerCase())
      })
    }
  },
  created() {
    // this.onProgress = true
    // console.log('category', this.$route.params.category)
    // this.$store.dispatch('activity/getObjsByCategory', this.$route.params.category).then(()=> {
    //   this.activities = this.$store.state.activity.objs
    //   this.onProgress = false
    // })
    // this.$store.dispatch('activity/getObjs');
    this.refreshList();
    this.$store.dispatch('location/getObjs');
    this.$store.dispatch('category/getObjs');
    this.$store.dispatch('activity/getAdmins');
    this.$store.dispatch('include/getObjs');
    this.$store.dispatch('vendor/getObjs');
  },
  computed: {
    // activities() {
    //   return this.$store.state.activity.objs;
    // },
    locations() {
      return this.$store.state.location.objs;
    },
    categories() {
      return this.$store.state.category.objs;
    },
    includes() {
      return this.$store.state.include.objs;
    },
    vendors() {
      return this.$store.state.vendor.objs;
    },
    admins() {
      return this.$store.state.activity.admins;
    },
    profile() {
      return this.$store.state.profile;
    }
  }
}
function string_to_slug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}
</script>

<style>
.tiptap-vuetify-editor__content {
  max-height: 150px;
}
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
 
/* h1, h2 {
  font-weight: normal;
} */
 
/* ul {
  list-style-type: none;
  padding: 0;
}
 
li {
  display: inline-block;
  margin: 0 10px;
} */
 
/* a {
  color: #42b983;
}
 */
.tiptap-vuetify-editor__content p {
    margin-top: 1px!important;
    margin-bottom: 1px!important;
    min-height: 1rem;
}
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  font-size: 0.8em;
}
</style> 

<style scoped>
.aktif {
  background-color: #60fa72;
  color: rgb(17, 131, 17);
  padding: 3px;
  font-weight:500;
}
.nonaktif {
  background-color: rgb(218, 218, 218);
  color: rgb(97, 97, 97);
  padding: 3px;
  font-weight:500;
}
.highlight {
  background-color: #0089DD;
  color: #FFFFFF;
  padding: 3px;
  font-weight:500;
}

</style>

<style lang="scss">
$gap: 20px;

.videos {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
    margin: {
      top: 0;
      bottom: 0;
    }
    list-style: none;
  }

  &__item {
    width: calc(50% - #{$gap / 2});

    &:nth-child(n + 3) {
      margin-top: $gap;
    }
  }
}
</style>
